import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';

import SpamtestAutoFixTaskService from '../../services/spamtestAutofixTask.service';
import SpamCheckerService from '../../services/spamChecker.service';

import SpamtestAutofixAiDetails from './spamtestAutofixAiDetails.js';

let stepsText = {
			"initial_email_no_risky_elements_initial_seedlist": "MailReach AI is testing your email without the most spam-sensitive elements (links, tracking, attachments, images) on the initial seed list to check if these are causing spam.",
	    "initial_email_no_risky_elements_initial_seedlist_fail": "MailReach AI tested your email without the most spam-sensitive elements on the initial seed list, but it still landed in spam.",
	    "initial_email_no_risky_elements_initial_seedlist_improved": "MailReach AI tested your email without the most spam-sensitive elements (links, tracking, attachments, images) on the initial seed list, and your inbox placement improved!",
	    "initial_email_no_risky_elements_initial_seedlist_success": "MailReach AI tested your email without the most spam-sensitive elements (links, tracking, attachments, images) on the initial seed list, and it landed 100% in the inbox!",

	    // Initial email on a different seed list
	    "initial_email_other_seedlist": "MailReach AI is testing your email on a different seed list to rule out bias.",
	    "initial_email_other_seedlist_fail": "MailReach AI tested your email on a different seed list to rule out bias, but it still landed in spam.",
	    "initial_email_other_seedlist_improved": "MailReach AI tested your email on a different seed list to rule out bias, and your inbox placement improved!",
	    "initial_email_other_seedlist_success": "MailReach AI tested your email on a different seed list to rule out bias, and it landed 100% in the inbox!",

	    // Email without the most spam-sensitive elements on a different seed list
	    "initial_email_no_risky_elements_other_seedlist": "MailReach AI is testing your email without the most spam-sensitive elements (links, tracking, attachments, images) on a different seed list to rule out bias.",
	    "initial_email_no_risky_elements_other_seedlist_fail": "MailReach AI tested your email without the most spam-sensitive elements (links, tracking, attachments, images) on a different seed list to rule out bias, but it still landed in spam.",
	    "initial_email_no_risky_elements_other_seedlist_improved": "MailReach AI tested your email without the most spam-sensitive elements (links, tracking, attachments, images) on a different seed list to rule out bias, and your inbox placement improved!",
	    "initial_email_no_risky_elements_other_seedlist_success": "MailReach AI tested your email without the most spam-sensitive elements (links, tracking, attachments, images) on a different seed list to rule out bias, and it landed 100% in the inbox!",

			"initial_email_with_risky_elements_other_seedlist": "MailReach AI is testing your email on a different seed list to eliminate bias.",
			"initial_email_with_risky_elements_other_seedlist_fail": "MailReach AI tested your email on a different seed list, but it still landed in spam.",
			"initial_email_with_risky_elements_other_seedlist_improved": "MailReach AI tested your email on a different seed list, and it improved your inbox placement.",
			"initial_email_with_risky_elements_other_seedlist_success": "MailReach AI tested your email on a different seed list, and it landed fully in the inbox!",

	    // Harmless email on the initial seed list
	    "harmless_email_initial_seedlist": "MailReach AI is testing a 100% harmless email on the initial seed list to determine if your content is causing spam.",
	    "harmless_email_initial_seedlist_fail": "MailReach AI tested a 100% harmless email on the initial seed list, but it still landed in spam.",
	    "harmless_email_initial_seedlist_improved": "MailReach AI tested a 100% harmless email on the initial seed list, and your inbox placement improved!",
	    "harmless_email_initial_seedlist_success": "MailReach AI tested a 100% harmless email on the initial seed list, and it landed 100% in the inbox!",

	    // Harmless email on a different seed list
	    "harmless_email_other_seedlist": "MailReach AI is testing a 100% harmless email on a different seed list to rule out bias.",
	    "harmless_email_other_seedlist_fail": "MailReach AI tested a 100% harmless email on a different seed list to rule out bias, but it still landed in spam.",
	    "harmless_email_other_seedlist_improved": "MailReach AI tested a 100% harmless email on a different seed list to rule out bias, and your inbox placement improved!",
	    "harmless_email_other_seedlist_success": "MailReach AI tested a 100% harmless email on a different seed list to rule out bias, and it landed 100% in the inbox!",

			"initial_email_with_harmless_subject_other_seedlist": "MailReach AI is testing your initial email but with a different subject to check if your subject is causing spam.",
			"initial_email_with_harmless_subject_other_seedlist_fail": "MailReach AI is testing your initial email but with a different subject, but it still landed in spam.",
			"initial_email_with_harmless_subject_other_seedlist_improved": "MailReach AI is testing your initial email but with a different subject, and your inbox placement improved!",
			"initial_email_with_harmless_subject_other_seedlist_success": "MailReach AI is testing your initial email but with a different subject, and it landed 100% in the inbox!",
}
let finalTexts = {
	// If the test suggests bias in the seed list
     "seedlist_bias": {
       "title": "Your email may have landed in spam due to bias",
       "details": "<p>MailReach AI tested your email on a different seed list, and it landed in the inbox. This suggests its initial placement in spam may have been influenced by bias.</p><p>Bias can sometimes occur in spam tests due to the way spam filters operate.</p><p>If you have any questions, contact us and include this URL so we can investigate.</p>"
     },

     // If the wording in the email is suspected to be the issue
     "wording_problem": {
       "title": "Your email may contain words or phrases that trigger spam filters",
       "details": "<p>MailReach AI tested a 100% harmless email, and it landed in the inbox. However, your email with your content went to spam.</p><p>The difference? Your wording. Certain words or phrases may be flagged as spammy.</p><p><b>Recommendation:</b> Review and modify your wording to avoid potentially risky language.</p>"
     },

     // If the issue couldn't be resolved and support is needed
     "reach_support": {
       "title": "Contact us for further assistance",
       "details": "<p>MailReach AI couldn’t resolve the spam issue this time.</p><p>This feature is still in Beta. Please reach out via chat so we can review your case and provide personalized support.</p>"
     },

     // If the wording is causing spam, but other factors may also contribute
     "wording_problem_maybe_more": {
       "title": "Your wording is causing spam, and there may be other factors",
       "details": "<p>MailReach AI tested your initial email without the most spam-sensitive elements, and it still landed in spam.</p><p>Then, MailReach AI tested a 100% harmless email, and it landed in the inbox.</p><p>These tests suggest two things:</p><p>1) Something in your wording is triggering spam filters.</p><p>2) Other elements may also be problematic, such as links, tracking, signatures, attachments, or images.</p><p>If you have any questions, contact us and include this URL so we can investigate.</p>"
     }
  }


let elementTexts = {
	'initial_email_no_risky_elements_except_attachments' : {except: 'attachement(s)', exceptTitle: 'attachement(s)'},
	'initial_email_no_risky_elements_except_tracking' : {except: 'tracking', exceptTitle: 'Tracking'},
	'initial_email_no_risky_elements_except_links' : {except: 'link(s)', exceptTitle: 'Link(s)'},
	'initial_email_no_risky_elements_except_signature' : {except: 'signature', exceptTitle: 'Signature'},
	'initial_email_no_risky_elements_except_fname' : {except: 'name', exceptTitle: 'Name'},
	'initial_email_no_risky_elements_except_images' : {except: 'image(s)', exceptTitle: 'Image(s)'},
	'initial_email_no_risky_elements_except_name' : {except: 'first name', exceptTitle: 'First Name'},
	'initial_email_no_risky_elements_except_subject' : {except: 'subject', exceptTitle: 'Subject'}
}


class SpamcheckerAutofixAi extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, spamtestAutofixTask: null, inapp_messages: [], loading: true, details: {}, openKeys: {}};

    this.runSpamcheckAi = this.runSpamcheckAi.bind(this);
		this.reloadSpamcheckerAi = this.reloadSpamcheckerAi.bind(this);
		this.loadSpamcheck = this.loadSpamcheck.bind(this);
		this.showLoaderNextStep = this.showLoaderNextStep.bind(this);
	}

  componentWillMount() {
    SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
      if(response.body){
        this.setState({spamtestAutofixTask: response.body, loading: false})
      }else{
        this.setState({spamtestAutofixTask: null, loading: false})
      }

			if(response.body){
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						8000
					);
				}
			}

			this.showLoaderNextStep()

    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	reloadSpamcheckerAi(){
		if(this.state.spamtestAutofixTask.completed){
			// Dont refresh if the task is already completed
			return;
		}
		SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
			if(response.body){
				this.setState({spamtestAutofixTask: response.body, loading: false})
			}else{
				this.setState({spamtestAutofixTask: null, loading: false})
			}

			if (!response.body){
				setTimeout(
					() => this.reloadSpamcheckerAi(),
					10000
				);
			}else{
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						10000
					);
				}
			}

			this.showLoaderNextStep();
		})
		.catch((error) => {
			this.setState({loading: false});
			toast.error('Oops. An error occured.');
		});
	}

  runSpamcheckAi(){
    SpamtestAutoFixTaskService.create(this.props.match.params.test_id).then((response) => {
			this.setState({spamtestAutofixTask: response.body, loading: false});
      //Launch reloading
			this.reloadSpamcheckerAi();
    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	showLoaderNextStep(){
		if(!this.state.spamtestAutofixTask){
			return;
		}

		if(!this.state.spamtestAutofixTask.process_data){
			return;
		}
		// Check if we have one step in progress
		let stepsInProgress = this.state.spamtestAutofixTask.process_data.filter((step) => !step.completed).length;


		let subSteps = this.state.spamtestAutofixTask.process_data.filter((step) => step['key'] == 'initial_email_isolating_risky_elements');

		let subStepsInProgress = 0;
		if(subSteps.length > 0){
			subStepsInProgress = subSteps[0]['details'].filter((step) => !step.completed).length;
		}

		let totalNextSteps = stepsInProgress + subStepsInProgress;

		if(totalNextSteps == 0 && !this.state.spamtestAutofixTask.completed){
			this.setState({nextStepLoading: true});
		}else{
			this.setState({nextStepLoading: false});
		}

	}

	loadSpamcheck(spamcheckInternalId, key){
		let openKeys = this.state.openKeys;
		if(openKeys[key]){
			openKeys[key] = false;
			this.setState({openDetails: openKeys});
			return;
		}
		openKeys[key] = true;
		this.setState({openDetails: openKeys})
		SpamCheckerService.getAutofix(spamcheckInternalId).then((response) => {
			let a = this.state.details || {};
			a[key] = response.body;
			this.setState({details: a});
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
			this.setState({loadNextTest: false});
		});
	}

	componentWillReceiveProps(nextProps) {
    this.loadNextSteps(nextProps);
	}

	handleCloseModal(){
		this.setState({showNextStepModal: null})
	}

	render() {
    if(this.state.loading){
      return(
        <div className="card text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

    if(!this.state.spamtestAutofixTask && !this.state.loading){
      return (
        <div className="card upgrade-spamchecker run-autofix-ai">
            <div className="col-md-12 text-left">
              <h2>Our AI can help you find out why this email ends up in spam.</h2>
              <p>Since {this.props.currentTest.account.email} has a reputation score of {this.props.currentTest.account.score}, this test landing in spam on professional inboxes is likely due to the content of this email. Let MailReach try to improve your deliverability by running multiple tests from your mailbox. This is a Beta feature. </p>
              <p><button onClick={this.runSpamcheckAi} className="btn btn-primary green-bck">Run MailReach AI</button></p>
            </div>
            <div className="clearfix"></div>
          </div>
      )
    }

		if(this.state.spamtestAutofixTask && this.state.spamtestAutofixTask.process_data.length == 0){
      return (
				<div className="card autofix-loading-no-card col-md-12 no-padding-left">
					 <div className="txt pull-left col-md-10 text-left">
						<div className="pull-left">
							Analyzing your email and initializing Autofix AI
						</div>
						<div className="loader">
												<Loader
												 type="Oval"
												 color="#b7b7b7"
												 height="15"
												 width="15"
												/>
											</div>
					 </div>
				</div>
      )
    }

		return (
      <div>


			{this.state.spamtestAutofixTask.process_data.map((detail, index) => {

				if(detail['final_key']){
					if(detail['final_key'] != 'list_spammy_elements'){
						return(
							<div className="card card-autofix-progress col-md-12">
								 <div className="txt pull-left col-md-10 text-left">
									<h2 className="autofix-result-title">Results : {finalTexts[detail['final_key']]['title']}</h2>

									<div dangerouslySetInnerHTML={{__html: finalTexts[detail['final_key']]['details']}}
										/>
								 </div>
							</div>
						)
					} else {

						if(this.state.spamtestAutofixTask.process_data.find((element) => element['key'] == 'initial_email_isolating_risky_elements')['details'].filter((detail) => detail['score'] < 7).length > 0){
							return(
								<div className="card card-autofix-progress col-md-12">
									 <div className="txt pull-left col-md-10 text-left">
										<h2 className="autofix-result-title">Conclusion: {this.state.spamtestAutofixTask.process_data.find((element) => element['key'] == 'initial_email_isolating_risky_elements')['details'].filter((detail) => detail['score'] < 7).length} element(s) identified to cause spam</h2>

										<div>

										<ul className="spammy-elements-list">
											{this.state.spamtestAutofixTask.process_data.find((element) => element['key'] == 'initial_email_isolating_risky_elements')['details'].filter((detail) => detail['score'] < 7).map((detail, index) => {
												return (
													<li>
														{elementTexts[detail['key']]['exceptTitle']}
													</li>
												)
											})}
										</ul>


										<p>Remove the element(s) from your email or replace them with alternatives.</p>
									 </div>
									 </div>
								</div>
							)
						} else {

							return(
								<div className="card card-autofix-progress col-md-12">
									 <div className="txt pull-left col-md-10 text-left">
										<h2 className="autofix-result-title">Conclusion (beta): No spam-causing elements identified</h2>

										<div>
											<p>MailReach AI tested your email without the elements most likely to cause spam, and it still landed in the inbox.</p>

											<p>This usually indicates that at least one element in your email triggers spam. However, when MailReach AI isolated and tested the potentially spammy elements, none resulted in spam.</p>

											<p>In this case, we couldn’t pinpoint the exact cause of the spam issue. Sometimes, this happens due to temporary factors outside your email content.</p>

											<p>We recommend running a new spam test with the same content to see if the results differ.</p>
									 </div>
									 </div>
								</div>
							)
						}
					}

				} else if (detail['key'] == 'initial_email_isolating_risky_elements') {
					return(
						<>
							{detail['details'].map((detailInner, index) => {
								return(
									<>
									{(detailInner.completed) &&
										<>
											<div className="card card-autofix-progress col-md-12">
														<>
															<div className="txt pull-left col-md-10 text-left">
															MailReach AI tested your email with only the {elementTexts[detailInner['key']]['except']} included, removing all other spam-sensitive elements. Result: {(detailInner['score'] > 7) ? "It is not causing spam" : 'It is causing spam'}
														 </div>


														 {(detailInner.score > 7) &&
																 <span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(detailInner['test_id'], detailInner['key'])}>
																	 Failed &nbsp;
																	 {!this.state.openKeys[detailInner['key']] &&
																		 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
																	 }
																	 {this.state.openKeys[detailInner['key']] &&
																		 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
																	 }
																 </span>
														 }

														 {(detailInner.score <= 7) &&
																 <span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(detailInner['test_id'], detailInner['key'])}>
																	 Success &nbsp;
																	 {!this.state.openKeys[detailInner['key']] &&
																		 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
																	 }
																	 {this.state.openKeys[detailInner['key']] &&
																		 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
																	 }
																 </span>
														 }



														</>
												 {this.state.openKeys[detailInner['key']] &&
													 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={detailInner['addresses']} details={this.state.details[detailInner['key']]} />
												 }
											</div>
											</>
									}

									{(!detailInner.completed) &&
										<>

											<div className="card card-autofix-progress col-md-12">
												 <div className="txt pull-left col-md-10 text-left">
												 MailReach AI is isolating the impact of the {elementTexts[detailInner['key']]['except']} by removing all other spam-sensitive elements from your email. This test will determine if it causes spam.
												 </div>

												 <Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
													 <div className="score running pull-right pulse">
														 Running
													 </div>
												 </Tooltip>
											</div>
										</>
									}
								</>
								)
							})}
						</>
					)

				} else {
				return(
					<>
					{(!detail.completed) &&
						<div className="card card-autofix-progress col-md-12">
							 <div className="txt pull-left col-md-10 text-left">
								{stepsText[detail['key']]}
							 </div>

							 <Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
								 <div className={"score running pull-right pulse" + ((stepsText[detail['key']].length > 138)? " second" : "")}>
									 Running
								 </div>
							 </Tooltip>
						</div>
					}

					{(detail.completed) &&

						<div className="card card-autofix-progress col-md-12">

								{(detail.score > 7) &&
									<>
										<div className="txt pull-left col-md-10 text-left">
			 							 	{stepsText[`${detail['key']}_success`]}
			 						 </div>
			 							 <span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
			 								 Success &nbsp;
			 								 {!this.state.openKeys[detail['key']] &&
			 									 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
			 								 }
			 								 {this.state.openKeys[detail['key']] &&
			 									 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
			 								 }
			 							 </span>
									</>
								}

								{(detail.score <= 7 && this.props.currentTest.test_type != 'btob') &&
									<>
										<div className="txt pull-left col-md-10 text-left">
											{stepsText[`${detail['key']}_fail`]}
										</div>

										 <span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
											 Failed &nbsp;
											 {!this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											 }
											 {this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
											 }
										 </span>
										</>
								}

								{((detail.score <= 7) && this.props.currentTest.test_type == 'btob' && (((this.props.currentTest.summary.SPAM) <= ((detail.spam_count || 0) + 1)) || (detail.score == 0))) &&
									<>
										<div className="txt pull-left col-md-10 text-left">
											{stepsText[`${detail['key']}_fail`]}
										</div>

										 <span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
											 Failed &nbsp;
											 {!this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											 }
											 {this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
											 }
										 </span>
										</>
								}

								{(detail.score < 7 && this.props.currentTest.test_type == 'btob' && detail.score > 0 &&((this.props.currentTest.summary.SPAM) > ((detail.spam_count || 0) + 1))) &&
									<>
										<div className="txt pull-left col-md-10 text-left">
											{stepsText[`${detail['key']}_improved`]}
										</div>

										 <span className="completed-btn-ai pull-right done orange-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
											 Improved &nbsp;
											 {!this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											 }
											 {this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-up"></i>
											 }
										 </span>
										</>
								}

							 {this.state.openKeys[detail['key']] &&
								 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={detail['addresses']} details={this.state.details[detail['key']]} />
							 }
						</div>

					}
				</>
			)
		}
		})

	}

	{(this.state.nextStepLoading) &&
					<div className="card autofix-loading-no-card col-md-12">
						 <div className="txt pull-left col-md-10 text-left">
						 		<div className="pull-left">
									Preparing the next analysis, this might take a moment...
								</div>
							<div className="loader">
								            <Loader
								             type="Oval"
								             color="#b7b7b7"
								             height="15"
								             width="15"
								            />
								          </div>
						 </div>

					</div>
			}

      </div>

		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpamcheckerAutofixAi));
